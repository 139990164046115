export const sameDayCollectionMessage = 'For collection today your gift will be ready 1 and a ½ hours after we’ve received the order';
export const futureDayCollectionMessage = 'Your gift will be made fresh on the day you have selected, please collect your gift 1 and a ½ hours after the shop is open.';
export const oldCustomerAlertMessageFF = 'Uh oh, looks like you’ve already ordered with us so you can’t use this code. Sorry!';
export const oldCustomerAlertMessageUK = 'Sorry it looks like you’ve ordered with us before, so you can’t use this code.';
export const signInRegisterAlertMessageFF = 'To snag your first order discount, please';
export const signInRegisterAlertMessageUK = 'To get your first order discount please';
export const invalidPromoCodeAlertMessage = 'The promotional code you entered is not valid.';
export const promotionalCodeApplyError = 'There has been an error applying the promotional code, please try again.';
export const emailSubject = 'Problem changing privacy settings';
export const privacySuccessMessage = 'Your privacy settings have been saved.';
export const privacyErrorMessage = 'There was a problem. Please contact us at';
export const deliveryInstructionNote = 'Please be patient, untill i open the door';
export const deliveryInstructionOption = 'Please be patient';
export const deliveryInstructionOptionNotToshow = 'Press to select';
export const deliveryInstructionLable = 'Delivery instructions';
export const ERROR_TOWN_LIST_PREFIX = 'Error retrieving a list of towns for';
export const ERROR_COUNTY_LIST_PREFIX = 'Error retrieving a list of counties for';
export const FLORISTS_NEAR_YOU = 'Florists near';
export const CHANGE_FLORIST = 'Change florist';
export const START_NEW_SEARCH = 'Start new search';
export const SEARCH_NEW_ADDRESS = 'Search new address';
export const LOAD_MORE_FLORISTS = 'Load more florists';
export const SELECT_FLORIST = 'Select florist';
export const OCCASION_REQUIRED_MESSAGE = 'Please select an occasion';
export const VALENTINES_PERSONALISED_MESSAGE = 'Be my Valentine - now, next year, and every year to come.';
export const MOTHERS_DAY_PERSONALISED_MESSAGE = 'Happy Mother’s Day. Hope you know just how much you are loved.';
