import { makeStyles } from 'tss-react/mui';
import { colors, spacing, typographySohne } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    heading: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm), { color: colors.supplementary.black, [theme.breakpoints.between('sm', 'xl')]: {
            color: colors.supplementary.white,
        }, [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xxl_bold), theme.typographySpacing.xxl_bold_lg), { color: colors.supplementary.white }), [theme.breakpoints.up('xl')]: Object.assign(Object.assign(Object.assign({}, theme.typography.xxxxl), theme.typographySpacing.xxxxl_sm), { color: colors.supplementary.white }) }),
    subheading: {
        color: colors.supplementary.black,
        [theme.breakpoints.up('md')]: {
            color: colors.supplementary.white,
        },
    },
    message: {
        color: colors.supplementary.black,
        [theme.breakpoints.between('sm', 'xl')]: {
            color: colors.supplementary.white,
        },
        [theme.breakpoints.up('md')]: {
            color: colors.supplementary.white,
        },
    },
    link: {
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            margin: 0,
            '& button': { width: '100%' },
            '&:not(:last-child)': {
                marginBottom: '0',
                marginRight: spacing.medium,
            },
        },
        [theme.breakpoints.up('md')]: {
            width: '46%',
        },
    },
    linkContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            maxWidth: '650px',
            display: 'flex',
            [`& .${classes.link}`]: {
                width: '50%',
                margin: 0,
                '& button': { width: '100%' },
                '&:first-child': {
                    marginRight: spacing.medium,
                },
            },
        },
    },
    outsideLinkContainer: {
        marginLeft: '20px',
        marginRight: '20px',
        display: 'block',
        marginTop: spacing.small,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    homeVideoContainer: {
        justifyContent: 'start',
    },
    outsideShowTrustpilotWidget: {
        minHeight: '60px',
        display: 'flex',
        marginTop: spacing.small,
        marginLeft: '20px',
        marginRight: '20px',
        padding: '18px',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    headingLine: {
        padding: 0,
        alignSelf: 'flex-start',
        display: 'block',
    },
    wrapHeading: {
        textAlign: 'left',
    },
    contentInner: {
        alignSelf: 'start',
        width: '100%',
        margin: `${spacing.medium} !important`, // We need to override the default margin of Generic Content Container
        [theme.breakpoints.up('sm')]: {
            margin: `${spacing.small} ${spacing.medium} !important`, // We need to override the default margin of Generic Content Container
        },
        [theme.breakpoints.up('md')]: {
            margin: `${spacing.medium} ${spacing.large} !important`, // We need to override the default margin of Generic Content Container
        },
    },
    fontSohne: {
        [`& .${classes.heading}`]: Object.assign(Object.assign(Object.assign({}, typographySohne.large_bold), theme.typographySpacing.large_bold_sm), { [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, typographySohne.xxl_bold), theme.typographySpacing.xxl_bold_sm), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xxxxl), theme.typographySpacing.xxxxl_sm) }),
    },
    newMessage: Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), { textAlign: 'left', color: colors.supplementary.white, [theme.breakpoints.up('sm')]: Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.small_bold_lg), [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm) }),
    content: {
        width: '100%',
        alignSelf: 'start',
        position: 'absolute',
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '50%',
        },
    },
    // We need to override the default positioning of Content Container
    positionBottom: {
        alignItems: 'flex-end !important',
        alignSelf: 'flex-end !important',
    },
    positionTop: {
        alignItems: 'flex-start !important',
        alignSelf: 'flex-start !important',
    },
    positionCenter: {
        alignItems: 'center !important',
        alignSelf: 'center !important',
    },
    homeVideoMobileContent: {
        height: '100%',
    },
}));
export default useStyles;
