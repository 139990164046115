import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params) => {
    var _a, _b;
    return ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: spacing.medium,
            [theme.breakpoints.up('sm')]: {
                minHeight: '292px',
                padding: spacing.large,
            },
            [theme.breakpoints.up('md')]: {
                padding: `${spacing.large} ${spacing.xl}`,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: '280px',
            },
        },
        title: Object.assign(Object.assign(Object.assign({}, theme.typography.medium_bold), theme.typographySpacing.medium_bold_sm), { [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.large_bold_sm) }),
        bodyText: {
            marginBottom: spacing.small,
            '& h1, & h2, & h3, & h4, & p,& div, & ul > li': Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
            '& ul': {
                display: 'inline-block',
            },
            '& ul > li': {
                marginBottom: '0 !important',
                paddingBottom: '0 !important',
            },
            '& > :last-child': {
                marginBottom: 0,
            },
        },
        image: {
            width: '100%',
            height: '100%',
        },
        fullWidth: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            // desktop image to be full width and it to fill container
            [theme.breakpoints.up('sm')]: {
                minHeight: '292px',
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: '280px',
            },
        },
        imageContainer: {
            padding: spacing.medium,
            minHeight: '404px',
            position: 'relative',
            [theme.breakpoints.up('sm')]: {
                minHeight: '292px',
                // padding: spacing.large,
            },
            [theme.breakpoints.up('md')]: {
                padding: `${spacing.large} ${spacing.xl}`,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: '280px',
            },
            '& img': {
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'block',
                height: '100%',
                width: '100%',
            },
        },
        bodyContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            color: colors.supplementary.white,
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: 0,
            left: 0,
            padding: spacing.medium,
            justifyContent: 'end',
            [theme.breakpoints.up('sm')]: {
                padding: spacing.large,
            },
            [theme.breakpoints.up('md')]: {
                padding: `${spacing.medium} ${spacing.xl}`,
            },
        },
        mobileImage: {
            minHeight: '336px',
            [theme.breakpoints.up('sm')]: {
                minHeight: '236px',
            },
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        overlayTextContainerDesktop: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            color: colors.supplementary.white,
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            background: `rgba(0,0,0,${((_a = _params === null || _params === void 0 ? void 0 : _params.transparentOverlay) !== null && _a !== void 0 ? _a : 0) / 100})`,
            top: 0,
            left: 0,
            justifyContent: 'end',
            padding: theme.isFlyingFlowers
                ? `${spacing.large} ${spacing.xl} ${spacing.large} ${spacing.xl}`
                : `${spacing.medium} ${spacing.xl} ${spacing.medium} ${spacing.xl}`,
        },
        overlayTextContainer: {
            marginTop: '200px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            color: colors.supplementary.white,
            position: 'relative',
            width: '100%',
            textAlign: 'center',
            background: `rgba(0,0,0,${((_b = _params === null || _params === void 0 ? void 0 : _params.transparentOverlay) !== null && _b !== void 0 ? _b : 0) / 100})`,
            padding: spacing.medium,
            [theme.breakpoints.up('sm')]: {
                padding: spacing.large,
            },
        },
        tranparentMobileView: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            minHeight: '200px',
            [theme.breakpoints.up('md')]: {
                minHeight: '528px',
            },
        },
        spotlightWrapper: {
            width: '100%',
        },
        link: {
            width: '100%',
            display: 'flex',
            marginBottom: spacing.small,
            '&:last-child': {
                marginBottom: 0,
            },
            '& button': {
                flexGrow: 1,
            },
        },
        linkButton: {
            margin: `0 auto `,
            width: '287px',
            height: '44px',
            padding: theme.isFlyingFlowers
                ? `10px ${spacing.medium} !important`
                : `15px ${spacing.medium} 13px ${spacing.medium} !important`,
            [theme.breakpoints.up('sm')]: {
                width: '335px',
            },
            [theme.breakpoints.up('md')]: {
                width: theme.isFlyingFlowers ? '335px' : '272px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '357px',
            },
            [theme.breakpoints.up('xl')]: {
                width: '488px',
            },
        },
        linkWrapper: {
            width: '100%',
            cursor: 'pointer',
        },
        productGridSpotlight: {
            padding: `${spacing.medium} 0 0 ${spacing.medium}`,
            [theme.breakpoints.up('md')]: {
                padding: `20px 0px 0px ${spacing.medium}`,
            },
        },
    });
});
export default useStyles;
