import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import useStyles from '../ProductDetailPurchase.styles';
const PersonalisedMessage = forwardRef(({ showPersonalisedMessage, personalisedMessages, occasionChosen, handleInputToPersonalisedMessageBox, maxLength }, ref) => {
    const { classes } = useStyles();
    useEffect(() => {
        if (ref && 'current' in ref && ref.current) {
            const personalisedMessageBoxField = ref.current;
            const handlePlaceholder = () => {
                var _a;
                if (((_a = personalisedMessageBoxField === null || personalisedMessageBoxField === void 0 ? void 0 : personalisedMessageBoxField.innerText) === null || _a === void 0 ? void 0 : _a.length) === 0 ||
                    (personalisedMessageBoxField === null || personalisedMessageBoxField === void 0 ? void 0 : personalisedMessageBoxField.innerHTML) === '<br>') {
                    personalisedMessageBoxField === null || personalisedMessageBoxField === void 0 ? void 0 : personalisedMessageBoxField.setAttribute('data-placeholder', 'Write here...');
                    personalisedMessageBoxField.innerHTML = '';
                }
                else {
                    personalisedMessageBoxField === null || personalisedMessageBoxField === void 0 ? void 0 : personalisedMessageBoxField.removeAttribute('data-placeholder');
                }
            };
            const handleFocus = () => {
                if ((personalisedMessageBoxField === null || personalisedMessageBoxField === void 0 ? void 0 : personalisedMessageBoxField.innerText.trim()) === '') {
                    personalisedMessageBoxField.innerHTML = '<br/>';
                    personalisedMessageBoxField === null || personalisedMessageBoxField === void 0 ? void 0 : personalisedMessageBoxField.removeAttribute('data-placeholder');
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.setStart(personalisedMessageBoxField.childNodes[0], 0);
                    range.collapse(true);
                    selection === null || selection === void 0 ? void 0 : selection.removeAllRanges();
                    selection === null || selection === void 0 ? void 0 : selection.addRange(range);
                }
            };
            const handleBeforeInput = (event) => {
                if (personalisedMessageBoxField.innerText.length >= maxLength &&
                    event.inputType !== 'deleteContentBackward') {
                    event.preventDefault();
                }
            };
            handlePlaceholder();
            personalisedMessageBoxField.addEventListener('input', handlePlaceholder);
            personalisedMessageBoxField.addEventListener('focus', handleFocus);
            personalisedMessageBoxField.addEventListener('beforeinput', handleBeforeInput);
            return () => {
                personalisedMessageBoxField === null || personalisedMessageBoxField === void 0 ? void 0 : personalisedMessageBoxField.removeEventListener('input', handlePlaceholder);
                personalisedMessageBoxField.removeEventListener('focus', handleFocus);
                personalisedMessageBoxField.removeEventListener('beforeinput', handleBeforeInput);
            };
        }
    }, [showPersonalisedMessage, ref, maxLength]);
    return (_jsx("div", { className: clsx(classes.messageBoxTextArea, classes.personalisedMessageBoxTextArea), contentEditable: "true", ref: ref, "aria-multiline": "true", onInput: handleInputToPersonalisedMessageBox, "data-placeholder": "Write here...", children: showPersonalisedMessage ? (_jsxs("div", { children: ["To ", _jsx("input", { className: classes.personalisedMessageRecipient, type: "text", value: "add name" }), _jsx("span", { children: "," }), _jsx("br", {}), _jsx("br", {}), personalisedMessages[occasionChosen], _jsx("br", {}), _jsx("br", {}), "From\u00A0", _jsx("input", { className: classes.personalisedMessageSender, type: "text", value: "add your name" })] })) : null }));
});
PersonalisedMessage.displayName = 'PersonalisedMessage';
export default PersonalisedMessage;
