export const flag_improve_availability_checker = {
    key: 'improve-availability-checker',
    simple_button: 'simple_button',
    creative_style: 'creative_style',
};
export const flag_uk_pdp_open_pricing = {
    key: 'uk-pdp-open-pricing',
    non_open_pricing: 'non_open_pricing',
};
export const flag_category_page_initial_results_count = {
    key: 'category-page-initial-results-count',
    explicit_count: 'explicit_count',
    default_count: 'default_count',
};
export const flag_bullets_on_funeral_products = {
    key: 'bullets-on-funeral-products',
    original: 'original',
    variation: 'variation',
};
export const enforce_component_load_sequence = { key: 'enforce-component-load-sequence' };
export const enforce_convert_sync_mode = { key: 'enforce-convert-sync-mode' };
export const click_and_collect = { key: 'click-and-collect' };
export const occasions_for_personalised_message = { key: 'occasions-for-personalised-message' };
export const show_personalised_message_box = {
    key: 'show-personalised-message-box',
    original: 'original',
    variation: 'variation',
};
