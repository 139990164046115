import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    heading: Object.assign(Object.assign(Object.assign({ display: 'flex', flexDirection: 'column' }, theme.typography.xl_regular), theme.typographySpacing.none), { color: colors.supplementary.white, [theme.breakpoints.up('sm')]: {
            marginBottom: spacing.small,
        }, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxl), { marginBottom: spacing.small }), [theme.breakpoints.up('lg')]: {
            marginBottom: spacing.medium,
        }, [theme.breakpoints.up('xl')]: Object.assign(Object.assign({}, theme.typography.xxxxl), { marginBottom: spacing.large }) }),
    subheading: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { marginBottom: '10px', textAlign: 'center', [theme.breakpoints.up('sm')]: {
            display: 'block',
            textAlign: 'left',
            marginBottom: spacing.medium,
        }, [theme.breakpoints.up('xl')]: Object.assign(Object.assign(Object.assign({}, theme.typography.large_bold), theme.typographySpacing.none), { marginBottom: spacing.medium }) }),
    message: {
        marginTop: spacing.small,
        textAlign: 'center',
        color: colors.supplementary.white,
        '& p': Object.assign({}, theme.typography.xxs_regular),
    },
    linkContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: { display: 'block' },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            margin: `0 -${spacing.xs}`,
            '& a': {
                width: '50%',
                flexDirection: 'column',
                flexGrow: 1,
                margin: 0,
                padding: `0 ${spacing.xs}`,
            },
        },
        '& button': { width: '100%' },
    },
    outsideLinkContainer: {
        marginTop: spacing.large,
        marginLeft: '20px',
        marginRight: '20px',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    outsideSubheading: {
        marginTop: spacing.medium,
        marginLeft: '20px',
        marginRight: '20px',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    link: {
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            flexGrow: 1,
            whiteSpace: 'pre-wrap',
        },
    },
    headingLine: {
        whiteSpace: 'nowrap',
        alignSelf: 'flex-start',
        padding: spacing.xs,
        '&:first-child': {
            transform: 'rotate(358deg) translate(5px,5px)',
            transformOrigin: 'bottom left',
        },
    },
    ffMessage: {
        display: 'block',
        color: colors.supplementary.black,
        '@media(orientation: landscape)': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    COBALT: {
        backgroundColor: colors.cobalt[500],
    },
    JADE: {
        backgroundColor: colors.jade[500],
    },
    WILD_EXOTIC: {
        backgroundColor: colors.wildExotic[500],
    },
    image: {
        '& img': {
            // flying flowers image is contained and positioned at the bottom
            objectFit: 'contain',
            objectPosition: 'bottom',
            [theme.breakpoints.down('xl')]: {
                maxHeight: '185px !important',
            },
        },
    },
    positionElement: {
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            position: 'unset !important',
        },
    },
    subMessageTitle: {},
    subMessage: {},
    subMessageContainer: {
        textAlign: 'center',
        display: 'flex',
        padding: `0 ${theme.spacing(2)}`,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            padding: '14px 0',
        },
        [`& .${classes.subMessageTitle}`]: Object.assign(Object.assign({}, theme.typography.small_bold), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_bold) }),
        [`& .${classes.subMessage}`]: Object.assign(Object.assign({}, theme.typography.small_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.large_regular) }),
    },
    ffCenterContentInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ffContent: {
        [theme.breakpoints.up('sm')]: {
            width: '50% !important',
        },
    },
    centerHeading: {
        alignSelf: 'center',
    },
    outsideShowTrustpilotWidget: {
        minHeight: '60px',
        display: 'flex',
        marginTop: spacing.small,
        marginLeft: '20px',
        marginRight: '20px',
        padding: '18px',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));
export default useStyles;
