import HeaderUI, { Props } from '@interflora/ui-components/build/components/Header/Header'
import {
  useSearchNBHitsContext,
  useSearchState,
  useSearchUpdate,
} from '@interflora/ui-components/build/components/SearchContext/SearchContext'
import { SiteContext, calculateTotalNBHitsCount } from '@interflora/ui-components/build/utils/common'
import SearchNBHitsStats from '@interflora/ui-components/build/utils/SearchNBHitsStats'
import { useLocalStorage } from '@interflora/ui-components/build/utils/utilityCustomHooks'
import algoliasearch from 'algoliasearch'
import AnalyticsContext from 'context/AnalyticsContext'
import { signOut } from 'lib/apolloClient'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect } from 'react'
import { Configure, Index, InstantSearch, InstantSearchSSRProvider, useQueryRules } from 'react-instantsearch'
import aa from 'search-insights'

const envPrefix = process.env.NEXT_PUBLIC_ALGOLIA_ENV_PREFIX
const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

const Header = (props: Props) => {
  const { shorthand, getConsignmentCount } = useContext(SiteContext)
  const analytics = useContext(AnalyticsContext)
  const { getFromLocalStorage } = useLocalStorage()
  const userToken = getFromLocalStorage('JWT_TOKEN')
  aa('setUserToken', userToken)
  const productIndex = `${envPrefix}_product_${shorthand}`
  const categoryIndex = `${envPrefix}_category_${shorthand}`
  const inspirationIndex = `${envPrefix}_inspiration_${shorthand}`

  const index = searchClient.initIndex(productIndex)
  const handleSignOut = async () => {
    await signOut()
    analytics.signOut()
    window.location.assign('/')
  }
  const router = useRouter()

  const handleRouteChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      getConsignmentCount?.refetch()
    }
  }, [getConsignmentCount])

  useEffect(() => {
    document?.addEventListener('visibilitychange', handleRouteChange)
    return () => {
      document?.removeEventListener('visibilitychange', handleRouteChange)
    }
  }, [handleRouteChange])

  const handleSuggestedSearchOrPreviousSearch = async (previousSearchValues: string) => {
    const algoliaSearchResults = await index.search(previousSearchValues, {
      distinct: true,
      hitsPerPage: 6,
      clickAnalytics: true,
    })
    const match = algoliaSearchResults?.userData?.find((data: any) => Boolean(data.redirect))
    if (match?.redirect) {
      analytics.searchRedirect(match?.redirect)
    }
    const redirectUrl = match?.redirect ? match.redirect : `/search?query=${encodeURIComponent(previousSearchValues)}`
    router.push(redirectUrl)
  }
  const searchTermAnalytics = (searchInputValue: string) => {
    analytics.searchTerm(searchInputValue)
  }
  const noSearchOverlayResultAnalytics = (message: string) => {
    analytics.searchOverlayNoResults(message)
  }

  // Wrap the Algolia InstantSearch with an empty SSR Provider to allow static HTML generation for the header
  return (
    <InstantSearchSSRProvider initialResults={{}}>
      <InstantSearch
        searchClient={searchClient}
        indexName={productIndex}
        future={{ preserveSharedStateOnUnmount: true }}
        insights={true}
        // Prevent additional searches until query length is 3 characters - still fires empty search
        // onStateChange={({ uiState, setUiState }) => {
        //   if (uiState[productIndex]?.query?.length > 2) {
        //     setUiState(uiState)
        //   }
        // }}
        // Prevent all searches until query length is 3 characters - deprecated
        searchFunction={(helper) => {
          if (helper.state.query?.length > 2) {
            helper.search()
          }
        }}
      >
        <SearchNBHitsStats />
        <SearchRedirect algoliaIndexes={[productIndex, inspirationIndex]} />
        <Index indexName={categoryIndex}>
          <SearchNBHitsStats />
        </Index>
        <Index indexName={inspirationIndex}>
          <SearchNBHitsStats />
        </Index>
        <Configure hitsPerPage={6} distinct clickAnalytics />
        <HeaderUI
          {...props}
          signOut={handleSignOut}
          handleSuggestedSearchOrPreviousSearch={handleSuggestedSearchOrPreviousSearch}
          searchTermAnalytics={searchTermAnalytics}
          noSearchOverlayResultAnalytics={noSearchOverlayResultAnalytics}
          sendMenuDrillDownAnalytics={(clickText) => analytics.menuDrillDownClick(clickText)}
          sendMenuLinkClickAnalytics={(menuText) => analytics.menulinkClick(menuText)}
        />
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}

const SearchRedirect = ({ algoliaIndexes }: { algoliaIndexes: string[] }) => {
  const { items } = useQueryRules()
  const router = useRouter()
  const setSearchQuery = useSearchUpdate()
  const searchQuery = useSearchState()
  const analytics = useContext(AnalyticsContext)
  const { nbHits, setnbHits } = useSearchNBHitsContext()

  // Need to get back: This should be in a useEffect, it could perform router changes and state changes on every rerender
  const match = items.find((data: any) => Boolean(data.redirect))
  if (searchQuery && match?.redirect) {
    analytics.searchRedirect(match.redirect)
    router.push(match.redirect)
    setSearchQuery('')
    setnbHits([])
  } else if (searchQuery) {
    const totalNbHits = calculateTotalNBHitsCount(nbHits, algoliaIndexes)
    if (totalNbHits) {
      router.push(`/search?query=${encodeURIComponent(searchQuery)}`)
    } else {
      router.push(`/nosearch?query=${encodeURIComponent(searchQuery)}`)
    }
    setSearchQuery('')
    setnbHits([])
  }

  return null
}

export default Header
