import { makeStyles } from 'tss-react/mui';
import { link } from '../../styles/jss/utils';
import { colors, spacing, statusColorsInterflora } from '../../styles/jss/variables';
const variantStyles = (theme) => ({
    variantLabelContainer: {
        display: 'flex',
        marginBottom: spacing.small,
    },
    variantLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: spacing.xs,
        background: colors.supplementary.white,
        border: theme.isFlyingFlowers ? `1px solid ${colors.black[700]}` : `1px solid ${colors.grey.grey3}`,
        borderRadius: '4px',
        marginRight: spacing.small,
        cursor: 'pointer',
        boxShadow: theme.isFlyingFlowers
            ? '0px 2px 2px rgba(60, 60, 59, 0.48), 0px 4px 8px rgba(60, 60, 59, 0.16)'
            : undefined,
        color: theme.isFlyingFlowers ? colors.black[700] : colors.grey.grey6,
        '& > span': Object.assign(Object.assign({}, theme.typography.xxs_regular), { [theme.breakpoints.up('md')]: Object.assign({}, theme.typography.small_regular) }),
        minHeight: '56px',
        [theme.breakpoints.up('md')]: {
            minHeight: '80px',
            marginRight: spacing.medium,
            paddingTop: '12px',
            paddingBottom: '12px',
        },
        '&:last-child': {
            marginRight: 0,
        },
        '&:hover': {
            background: colors.grey.grey1,
            border: `1px solid ${colors.grey.grey6}`,
            boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.32)`,
        },
        '&:focus': {
            backgroundColor: theme.isFlyingFlowers ? `${colors.lazyLime[300]} !important` : colors.primary.gold1,
            border: theme.isFlyingFlowers
                ? `1px solid ${theme.statusColors.success.dark}`
                : `1px solid ${colors.supplementary.black}`,
            boxShadow: theme.isFlyingFlowers ? 'none' : '',
            outline: 'none',
        },
    },
    variantLabelChecked: {
        backgroundColor: theme.isFlyingFlowers ? `${colors.lazyLime[300]} !important` : colors.primary.gold1,
        border: theme.isFlyingFlowers
            ? `1px solid ${theme.statusColors.success.dark}`
            : `1px solid ${colors.supplementary.black}`,
        boxShadow: theme.isFlyingFlowers ? 'none' : '',
    },
    styleVariantModal: {
        '& .MuiPaper-root': {
            '& header': {
                // marginBottom: spacing.xs,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 0,
                },
                '& h3': {
                    paddingLeft: 0,
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    [theme.breakpoints.up('md')]: Object.assign(Object.assign(Object.assign({ paddingLeft: '73px', margin: '0 !important' }, theme.typography.large_regular), theme.typographySpacing.large_regular_sm), { paddingTop: '18px', paddingBottom: '32px' }),
                },
                '& > div': {
                    position: 'relative',
                    [theme.breakpoints.up('lg')]: {
                        top: '-12px',
                    },
                },
            },
            padding: '16px 20px 37px 20px',
            [theme.breakpoints.up('lg')]: {
                padding: '32px 40px 0 40px',
                maxWidth: '1064px',
                width: '100%',
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.up('md')]: {
                    padding: '0px 73px 54px 73px !important',
                    width: '100%',
                },
            },
        },
    },
    variantStyleContainer: {
        alignItems: 'center',
    },
});
const specialInstructionsStyles = (theme) => ({
    specialInstructionsTitle: Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm),
    specialInstructionsMessage: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm), { marginBottom: spacing.small }),
    specialInstructions: {
        marginBottom: spacing.small,
        '& textarea': {
            height: '120px !important ',
            [theme.breakpoints.up('md')]: {
                height: '104px !important',
            },
        },
    },
    specialInstructionsSeparator: {
        borderTop: `1px solid ${colors.grey.grey2}`,
        marginBottom: `${spacing.medium} !important`,
        paddingTop: `${spacing.large} !important`,
        marginTop: spacing.large,
    },
    specialInstructionContainer: {
        position: 'relative',
    },
});
const internationalJourneyStyles = (theme) => ({
    internationalDeliveryLink: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.none), link(colors.primary.gold4)), { color: colors.primary.gold5, marginBottom: spacing.small, marginTop: spacing.xs, [theme.breakpoints.up('md')]: {
            marginBottom: spacing.large,
        } }),
    internationalDelivery: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    internationalLink: {
        '& .MuiPaper-root': {
            [theme.breakpoints.up('md')]: {
                padding: '52px 40px 82px 40px',
                width: '100%',
            },
            '& .MuiDialogContent-root': {
                [theme.breakpoints.up('md')]: {
                    padding: '0 96px 0 96px !important',
                },
            },
        },
    },
});
const addressStyles = (theme) => ({
    selectAddressContainer: {
        display: 'flex',
        cursor: 'pointer',
    },
    selectAddressCaption: Object.assign(Object.assign(Object.assign({ color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }, theme.typography.xxs_regular), theme.typographySpacing.none), link(theme.isFlyingFlowers ? colors.wildExotic[500] : colors.primary.gold4)),
    saveAddressIcon: {
        color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black,
        marginRight: spacing.xs,
        '& .material-icons': {
            fontSize: '22px',
        },
    },
    emptyAddresses: {
        marginBottom: spacing.medium,
    },
});
const tributeStyles = (theme) => ({
    tributeDeliveryMessage: Object.assign(Object.assign({}, theme.typography.medium_regular), theme.typographySpacing.medium_regular_sm),
    tributeActions: {
        marginBottom: spacing.small,
    },
    tributeUpdateCompleted: {
        marginBottom: spacing.small,
    },
    tributeEditCaption: Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.xxs_bold_lg),
});
const recipientStyles = (theme) => ({
    recipientDetailsContainer: {
        clear: 'both',
    },
    recipientLabel: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.xxs_bold_lg), { color: colors.black[700] }),
    recipientPhoneNumber: {
        marginBottom: spacing.large,
    },
});
const useStyles = makeStyles()((theme, _params, classes) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ error: {
        border: `2px solid ${theme.statusColors.error.base}`,
        backgroundColor: colors.supplementary.white,
        marginBottom: spacing.xs,
        padding: `calc(${spacing.xs} - 1px)`,
    }, purchase: {}, link: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_lg), (theme.isFlyingFlowers ? link(colors.wildExotic[500]) : link(colors.primary.gold4))), { color: theme.isFlyingFlowers ? colors.black[700] : colors.primary.gold5 }), heading: Object.assign(Object.assign(Object.assign({}, theme.typography.small_bold), theme.typographySpacing.none), { color: theme.isFlyingFlowers ? colors.black[700] : colors.supplementary.black, marginBottom: spacing.small, display: 'flex' }), subHeading: Object.assign(Object.assign({}, theme.typography.xxs_bold), { paddingBottom: spacing.xs, paddingTop: '0px' }), adjustHeadingWithMargin: {
        marginBottom: spacing.small,
    }, headingAndSignUp: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexFlow: 'row wrap',
        flexDirection: 'initial',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    }, deliveryAddressTitle: {
        marginRight: 'initial',
        marginBottom: '21px',
        flex: '1 0 auto',
        [theme.breakpoints.up('md')]: {
            marginRight: spacing.xs,
        },
        [theme.breakpoints.between('sm', 'xl')]: {
            marginBottom: '21px',
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 0,
        },
    }, input: {
        marginBottom: spacing.small,
    }, messageBox: { marginBottom: spacing.small, position: 'relative' }, messageBoxContent: Object.assign({}, theme.typography.xxs_regular), messageBoxTitle: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_bold), theme.typographySpacing.xxs_regular_sm), { marginBottom: spacing.xxs }), messageBoxTextArea: {
        padding: '8px 8px 20px 8px',
        '& textarea': {
            minHeight: '160px',
        },
    }, askSuggestion: {
        display: 'flex',
        justifyContent: 'flex-end',
    }, suggestionMessageButton: Object.assign(Object.assign(Object.assign(Object.assign({}, theme.typography.small_regular), theme.typographySpacing.small_regular_sm), link(colors.primary.gold4)), { color: colors.primary.gold5, marginTop: spacing.xs, display: 'flex', justifyContent: 'end center' }), personalisedMessageBoxTextArea: Object.assign(Object.assign({ border: `1px solid ${colors.grey.grey7}`, background: colors.background.LIGHT_GREY, minHeight: '218px', alignItems: 'center', width: '100%', position: 'relative', color: colors.grey.grey7, outline: 'none' }, theme.typography.small_regular), { padding: '8px 8px 20px 8px', '&:before': Object.assign({ content: '"Your message"', pointerEvents: 'none', display: 'block' }, theme.typography.small_bold), '&:after': Object.assign(Object.assign({ content: 'attr(data-placeholder)', color: colors.grey.grey6, position: 'absolute' }, theme.typography.small_regular), { pointerEvents: 'none' }), '&:focus': {
            boxShadow: '0px 0px 8px #008be9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
        }, '& input': {
            color: statusColorsInterflora.error.base,
            display: 'inline-block',
            border: 'none',
            outline: 'none',
            fontSize: 'inherit',
            fontStyle: 'italic',
            padding: 0,
        }, '&:hover': {
            boxShadow: theme.isFlyingFlowers ? '0px 4px 8px rgba(60, 60, 59, 0.32)' : '0px 4px 8px rgba(0, 0, 0, 0.32)',
            backgroundColor: colors.background.WHITE,
        }, '&:active': {
            backgroundColor: colors.background.WHITE,
        }, '&:focus-within': {
            boxShadow: '0px 0px 8px #008BE9, 0px 4px 8px rgba(0, 0, 0, 0.32)',
            backgroundColor: colors.background.WHITE,
        } }), personalisedMessageRecipient: { maxWidth: '9ch' }, personalisedMessageSender: { maxWidth: '13ch' }, messageBoxCharCount: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm), { position: 'absolute', marginLeft: spacing.small, bottom: '1px', right: '10px' }), messageBoxDesc: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm), { marginBottom: spacing.xs }), disabledAddressLine: {
        pointerEvents: 'none',
    }, addressLineCheckBox: {
        marginTop: spacing.xs,
        marginBottom: spacing.medium,
        '& .MuiFormControlLabel-label': {
            padding: '10px 0 0 6px',
        },
    }, openPriceInput: {
        marginBottom: spacing.medium,
        position: 'relative',
        '& > div > div': {
            padding: spacing.small,
        },
        '& label': {
            padding: '1px !important',
        },
        '& span': {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }, currency: {
        '&.MuiInputAdornment-positionStart': {
            marginRight: 0,
            '& .MuiTypography-colorTextSecondary': Object.assign(Object.assign({ color: 'rgba(0,0,0,0.87)' }, theme.typography.small_regular), { paddingTop: 0, paddingBottom: 0 }),
        },
    }, caption: Object.assign(Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_lg), { color: theme.isFlyingFlowers ? colors.black[700] : undefined }), pending: {
        borderTop: `1px solid ${colors.grey.grey4}`,
        paddingTop: `${spacing.large} !important`,
        color: `${colors.grey.grey4} !important`,
        marginBottom: spacing.medium,
    }, next: {
        marginBottom: spacing.xl,
    }, completed: {
        marginBottom: spacing.xxl,
    }, delivery: {
        display: 'flex',
        background: colors.primary.gold1,
        paddingTop: spacing.xs,
        paddingBottom: spacing.xs,
        paddingLeft: spacing.small,
        paddingRight: spacing.small,
        marginBottom: spacing.xxl,
    }, deliveryIcon: {
        color: colors.primary.gold5,
    }, deliveryLabel: {
        marginLeft: spacing.xs,
        color: colors.grey.grey6,
    }, saveButton: {
        marginBottom: `${spacing.medium} !important`,
    }, marginBottomBelowTextField: {
        marginBottom: spacing.medium,
        '& textarea': Object.assign(Object.assign({}, theme.typography.small_regular), { color: colors.black[600] }),
    }, phoneCaption: Object.assign(Object.assign({}, theme.typography.xxs_regular), theme.typographySpacing.xxs_regular_sm), addNewAddressButton: {
        marginBottom: spacing.xl,
    }, horizontalLine: {
        marginTop: spacing.large,
        marginBottom: spacing.large,
        [theme.breakpoints.up('md')]: {
            marginTop: spacing.xxl,
            marginBottom: spacing.xxl,
        },
        display: 'flex',
        flexDirection: 'row',
        [`& .${classes.textContent}`]: Object.assign(Object.assign({ width: '94px', textAlign: 'center' }, theme.typography.small_bold), { [theme.breakpoints.up('md')]: {} }),
        '&:before, &:after': {
            content: '""',
            flex: '1 1',
            borderBottom: `1px solid ${colors.grey.grey4}`,
            margin: 'auto',
        },
    }, textContent: {}, characterCount: Object.assign(Object.assign(Object.assign({ position: 'absolute', marginLeft: spacing.small }, theme.typography.xxs_regular), theme.typographySpacing.none), { top: '120px', right: '11px', [theme.breakpoints.up('md')]: {
            right: '9px',
            top: '100px',
        } }), chooseDeliveryText: {
        [theme.breakpoints.between('sm', 'xl')]: {
            margin: '0 62px',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            margin: '0 auto',
            maxWidth: '1064px',
        },
    }, adjustSignIn: {
        marginBottom: '0 !important',
        position: 'relative',
    } }, variantStyles(theme)), specialInstructionsStyles(theme)), internationalJourneyStyles(theme)), tributeStyles(theme)), addressStyles(theme)), recipientStyles(theme)), { icon: {}, collectionTitle: {
        paddingTop: spacing.small,
    }, collectionTime: {
        paddingTop: spacing.small,
    } })));
export default useStyles;
