import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import useStyles from './ContentContainer.styles';
import clsx from 'clsx';
import RawHtml from '../../RawHtml/RawHtml';
import Quotation from '../Quotation/Quotation';
import LinkContainer from '../LinkContainer/LinkContainer';
import { getTextContentPositionClass } from '../../../utils/common';
const getTextAlignmentClass = (textAlignment, classes) => {
    switch (textAlignment === null || textAlignment === void 0 ? void 0 : textAlignment.toUpperCase()) {
        case 'RIGHT':
            return classes.textRight;
        case 'LEFT':
            return classes.textLeft;
        case 'CENTER':
            return classes.textCenter;
        default:
            return classes.textCenter;
    }
};
const ContentContainer = (props) => {
    const { heading, message, citation, textAlignment, links, linkStyle, buttonVariant, isQuotation, isFlyingFlowers, textContentPosition, classNames, titleTextVariant, headingColor, maxLinks, headingTag, contentFeatureAnalytics, } = props;
    const isButton = (linkStyle === null || linkStyle === void 0 ? void 0 : linkStyle.toUpperCase()) === 'BUTTON';
    const { classes } = useStyles({ headingColor });
    const HeadingTag = headingTag ? headingTag === null || headingTag === void 0 ? void 0 : headingTag.toLowerCase() : 'div';
    const textContentPositionClass = getTextContentPositionClass(textContentPosition, classNames, 'CENTER');
    const textAlignmentClass = getTextAlignmentClass(textAlignment, classes);
    return (_jsx(Box, { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.container, classes === null || classes === void 0 ? void 0 : classes.contentFeature, classNames === null || classNames === void 0 ? void 0 : classNames.content, !isFlyingFlowers ? textContentPositionClass : '', textAlignmentClass, isQuotation ? classes.contentWithQuotationMarks : ''), children: (heading || message || citation || !!(links === null || links === void 0 ? void 0 : links.length)) && (_jsxs("div", { className: clsx(classes.contentInner, classNames === null || classNames === void 0 ? void 0 : classNames.contentInner, classNames === null || classNames === void 0 ? void 0 : classNames.mediaContentInner, isQuotation ? classes.contentFeatureQuoteContainer : ''), children: [_jsx(Quotation, { isQuotation: isQuotation, heading: heading, headingColor: headingColor, titleTextVariant: titleTextVariant, classNames: classNames, headingTag: HeadingTag }), message && (_jsx(RawHtml, { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.message, citation ? classes.citationMessage : ''), content: message })), citation && (_jsx(Box, { className: clsx(classNames === null || classNames === void 0 ? void 0 : classNames.message, classes.citation), component: 'p', children: citation })), links && (_jsx(LinkContainer, { links: links, isButton: isButton, buttonVariant: buttonVariant, classNames: classNames, maxLinks: maxLinks, contentFeatureAnalytics: contentFeatureAnalytics }))] })) }));
};
export default ContentContainer;
