import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    videoContainer: {
        // default on mobile the image is full width and positioned above or below the content
        width: '100%',
        position: 'relative',
        // Make square on mobile
        paddingTop: '100%',
        [theme.breakpoints.up('sm')]: {
            // default on desktop is for image to be 50% wide unless fullImage
            width: '50%',
        },
        // backup  image min height if other min heights not used
        minHeight: '200px',
    },
    squareVideoContainer: {
        paddingTop: '100%',
        '& > div': {
            position: 'unset',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '50%',
        },
    },
}));
export default useStyles;
