import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Alert from '../Alert/Alert';
import Link from '../Link/Link';
import useStyles from './Checkout.styles';
import CheckoutBillingDetails, { validationSchema as validationSchemaBD, } from './CheckoutBillingDetails/CheckoutBillingDetails';
import CheckoutContactInfo, { validationSchema as validationSchemaCI } from './CheckoutContactInfo/CheckoutContactInfo';
import CheckoutOrderSummary from './CheckoutOrderSummary/CheckoutOrderSummary';
import CheckoutPayment from './CheckoutPayment/CheckoutPayment';
import { checkoutSteps, isPreferenceCookieAccepted } from '../../utils/common';
import CheckoutErrorAlert from './ChekoutErrorAlert';
const useCutOffRemaining = (initialValue) => {
    const [cutOffRemaining, setCutOffRemaining] = useState(initialValue);
    // If cart is updated with new cut off remaining then store the value
    useEffect(() => {
        setCutOffRemaining(initialValue);
    }, [initialValue]);
    // Every time cut off remaining stored value is changed, create a new timer that reduces it to the nearest minute, and then update the stored value
    useEffect(() => {
        const seconds = typeof cutOffRemaining === 'number' && cutOffRemaining > 0 && (cutOffRemaining % 60 || 60);
        const timeout = cutOffRemaining &&
            seconds &&
            setTimeout(() => {
                setCutOffRemaining(cutOffRemaining - seconds);
            }, seconds * 1000);
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [cutOffRemaining]);
    return cutOffRemaining;
};
const CutOffPassedAlert = ({ day }) => (_jsx(Alert, { message: _jsxs(_Fragment, { children: [_jsxs("p", { children: ["We can no longer complete your order for delivery ", day, " because you have passed the order cut-off time."] }), _jsxs("p", { children: [_jsx(Link, { url: "/basket", label: "Update your order by selecting a new delivery date" }), "."] })] }), type: "alertError" }));
const Checkout = (props) => {
    var _a, _b, _c;
    const { classes } = useStyles();
    const { cart, currentStep, isSubmitting, updateStep1, updateStep2, completeOrder, logOrder, logOrderPaypalAndKlarna, handleSaveMessage, handleSaveOccasion, formatAddressCallback, addDeliveryPass, handleChangePaymethod, isPaymentLoading, redirectPaymentError, saveCheckoutUILogs, deliveryPassPromotionText, deliveryPassPromotionType, setIsPaymentProgress, isPaymentInProgress, paymentSelection, sendPersonalMessageLocationToAnalytics, trackEnterManualAddressClick, } = props;
    const stepHeading = (_a = checkoutSteps[currentStep]) === null || _a === void 0 ? void 0 : _a.heading;
    const validationSchema = [validationSchemaCI, validationSchemaBD];
    const [errorAlert, setErrorAlert] = useState();
    const [isKlarnaPayError, setKlarnaPayError] = useState(false);
    const containerRef = React.useRef(null);
    const onSubmit = (values) => {
        var _a, _b;
        if (currentStep === 0) {
            updateStep1(values);
            const isPerformanceCookieAccepted = isPreferenceCookieAccepted();
            if (((_a = values === null || values === void 0 ? void 0 : values.billingAddress) === null || _a === void 0 ? void 0 : _a.email) && window.ometria && isPerformanceCookieAccepted) {
                window.ometria.identify((_b = values === null || values === void 0 ? void 0 : values.billingAddress) === null || _b === void 0 ? void 0 : _b.email);
            }
        }
        currentStep === 1 && updateStep2(values);
    };
    // handle cutOffRemaining
    const cutOffDay = ((_b = cart === null || cart === void 0 ? void 0 : cart.cutOff) === null || _b === void 0 ? void 0 : _b.day) || 'today';
    const cutOffRemaining = useCutOffRemaining(((_c = cart === null || cart === void 0 ? void 0 : cart.cutOff) === null || _c === void 0 ? void 0 : _c.secondsRemaining) || undefined);
    const cutOffPassedAlert = (cutOffRemaining === 0 && _jsx(CutOffPassedAlert, { day: cutOffDay })) || undefined;
    const cutOffMins = !!cutOffRemaining && Math.ceil(cutOffRemaining / 60);
    const handleErrorAlert = useCallback(() => {
        var _a, _b;
        const unavailableErrorItemsList = !!((_a = cart === null || cart === void 0 ? void 0 : cart.unavailableItems) === null || _a === void 0 ? void 0 : _a.length)
            ? (_b = cart === null || cart === void 0 ? void 0 : cart.unavailableItems) === null || _b === void 0 ? void 0 : _b.filter((item) => { var _a; return !!((_a = item.checkoutError) === null || _a === void 0 ? void 0 : _a.length); })
            : [];
        if (!!(unavailableErrorItemsList === null || unavailableErrorItemsList === void 0 ? void 0 : unavailableErrorItemsList.length)) {
            const singleError = unavailableErrorItemsList[0].checkoutError && unavailableErrorItemsList[0].checkoutError[0];
            const errorMessage = (unavailableErrorItemsList === null || unavailableErrorItemsList === void 0 ? void 0 : unavailableErrorItemsList.length) > 1 ? (_jsx(CheckoutErrorAlert, { unavailableItems: unavailableErrorItemsList })) : (_jsx(CheckoutErrorAlert, { errorType: singleError }));
            setErrorAlert(errorMessage);
        }
    }, [cart === null || cart === void 0 ? void 0 : cart.unavailableItems]);
    useEffect(() => {
        if (currentStep === 2) {
            handleErrorAlert();
            document.addEventListener('visibilitychange', handleErrorAlert);
        }
        return () => document.removeEventListener('visibilitychange', handleErrorAlert);
    }, [currentStep, handleErrorAlert]);
    useEffect(() => {
        if (currentStep === 2 && isKlarnaPayError) {
            const offsetTop = containerRef.current ? containerRef.current.offsetTop - 100 : 0;
            window.scrollTo({ top: offsetTop });
        }
    }, [currentStep, isKlarnaPayError]);
    return (cart === null || cart === void 0 ? void 0 : cart.id) ? (_jsx(Formik, { enableReinitialize: true,
        initialValues: Object.assign(Object.assign({}, cart), { billingAddressCountrySelector: '', online: cart.marketing.online, post: cart.marketing.post, email: cart.marketing.email, sms: cart.marketing.sms }),
        validationSchema: validationSchema[currentStep],
        onSubmit, children: _jsxs(Form, { children: [!!cutOffRemaining && (_jsx(Alert, { message: `Order in the next ${cutOffMins} ${cutOffMins > 1 ? 'mins' : 'min'} for delivery ${cutOffDay}`, type: "alertTimer", className: classes.alertTimer })), _jsx("div", { ref: containerRef, children: isKlarnaPayError ? (_jsx(Alert, { className: clsx(classes.error, classes.klarnaError), type: "alertError", message: "We are unable to process your payment at this time. Try again using a different payment method." })) : null }), _jsx("div", { className: classes.secure, children: "Secure checkout" }), _jsx("div", { className: classes.heading, children: stepHeading }), _jsxs("div", { className: classes.container, children: [_jsxs("div", { className: clsx(classes.box, classes.steps), children: [_jsx(CheckoutContactInfo, { step: 0,
                                    showMarketingPreferences: cart.showMarketingPreferences,
                                    currentStep,
                                    isSubmitting,
                                    cutOffPassedAlert }), _jsx(CheckoutBillingDetails, { step: 1,
                                    cart,
                                    currentStep,
                                    isSubmitting,
                                    formatAddressCallback,
                                    cutOffPassedAlert,
                                    trackEnterManualAddressClick }), _jsx(CheckoutPayment, { step: 2,
                                    cart,
                                    currentStep,
                                    completeOrder,
                                    logOrder,
                                    logOrderPaypalAndKlarna,
                                    isSubmitting,
                                    cutOffPassedAlert,
                                    errorAlert,
                                    handleChangePaymethod,
                                    isPaymentLoading,
                                    redirectPaymentError,
                                    saveCheckoutUILogs,
                                    setIsPaymentProgress,
                                    isPaymentInProgress,
                                    setKlarnaPayError,
                                    paymentSelection })] }), _jsx("div", { className: classes.orderSummaryContainer, children: _jsx("div", { className: classes.orderSummary, children: _jsx(CheckoutOrderSummary, { cart, currentStep, handleSaveMessage, handleSaveOccasion, addDeliveryPass: addDeliveryPass, deliveryPassPromotionText: deliveryPassPromotionText, deliveryPassPromotionType: deliveryPassPromotionType, sendPersonalMessageLocationToAnalytics: sendPersonalMessageLocationToAnalytics }) }) })] })] }) })) : null;
};
export default Checkout;
