import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useCallback, useContext } from 'react';
import { SiteContext } from '../../utils/common';
import GenericContent from '../GenericContent/GenericContent';
import useStyles from './BlogHeroBanner.styles';
import Head from 'next/head';
import { jsonLdScriptProps } from 'react-schemaorg';
import { Box } from '@mui/material';
const BlogHeroBanner = (props) => {
    const { isFlyingFlowers } = useContext(SiteContext);
    const { headingColor = isFlyingFlowers ? 'COBALT' : 'BLACK', message, isLocation, contentPosition, isFullWidthImage, isFromBlogHeroBanner = true, blogSchemaSEO, headingTag = 'H1', heading, } = props;
    const { classes } = useStyles();
    const getSeoSchema = useCallback((blogSchemaSEO) => {
        try {
            return JSON.parse(blogSchemaSEO);
        }
        catch (error) {
            return '';
        }
    }, []);
    const getWrappedText = useCallback((text, type) => {
        return typeof text === 'string' && text.trim()
            ? text.split('/').map((line) => (_jsx("span", { className: clsx(isFlyingFlowers ? classes.headingLine : classes.newHeading, headingColor && type === 'heading' && classes[headingColor], isFlyingFlowers && contentPosition === 'CENTER' ? classes.centerHeading : ''), children: line.trim() }, `${line}_index`)))
            : text;
    }, [classes, contentPosition, headingColor, isFlyingFlowers]);
    return (_jsxs(_Fragment, { children: [_jsx(GenericContent, Object.assign({}, props, { classNames: classes, fullWidthImage: true, contentPosition: isFlyingFlowers ? contentPosition : 'LEFT', heading: getWrappedText(heading, 'heading'), message: isFlyingFlowers || isLocation ? message : getWrappedText(message), headingLevel: isLocation || !!contentPosition ? 'NONE' : 1, minHeight: !!isFlyingFlowers && isFullWidthImage ? 'LARGE' : 'BLOG', textAlignment: isFlyingFlowers && contentPosition === 'CENTER' ? 'CENTER' : 'LEFT', mobileContentPosition: "OVER", isFFHeroImage: !!isFlyingFlowers, isFullWidthImage: isFullWidthImage, isFromBlogHeroBanner: !isLocation, headingTag: headingTag, headingColor: !isFlyingFlowers ? headingColor : undefined, font: 'SOHNE', isLocation: isLocation })), message && isFlyingFlowers ? (_jsx(Box, { component: 'p', className: clsx(classes.message, classes.outerMessage, contentPosition === 'CENTER' ? classes.textCenter : ''), children: message })) : null, blogSchemaSEO && getSeoSchema(blogSchemaSEO) ? (_jsx(Head, { children: _jsx("script", Object.assign({}, jsonLdScriptProps(getSeoSchema(blogSchemaSEO)))) })) : null] }));
};
export default BlogHeroBanner;
